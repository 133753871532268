import React from "react";
import SearchIcon from "@/public/icons/SearchIcon.svg";
import CategoryIcon from "@/public/icons/CategoryIcon.svg";
import LocationIcon from "@/public/icons/LocationIcon.svg";
import ArrowDownIcon from "@/public/icons/ArrowDownIcon.svg";
import SortIcon from "@/public/icons/SortIcon.svg";
import FilterIcon from "@/public/icons/FilterIcon.svg";
import { useFormContext } from "react-hook-form";
import Select, { components } from "react-select";
import { useTranslation } from "next-i18next";
import SiraafMenu from "@/src/components/Common/Menu/SiraafMenu/SiraafMenu.js";

const MultiSearchInput = (props) => {
  const {
    className = "",
    countries = [],
    categories = [],
    hasCategory = true,
    hasSortBy = false,
    hasShipTo = true,
    hasFilter = false,
    onFilterClick,
    placeholder = "Search",
    sortBy = [],
  } = props;
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const { t } = useTranslation("common");

  const handleChangeLocation = (val) => {
    setValue("country_id", val.value);
  };
  const handleChangeCategory = (val) => {
    setValue("category_id", val.value);
  };
  const handleChangeSort = (val) => {
    setValue("order_by", val.value);
  };

  const restructureSelectItems = (list) => {
    return list.map((item) => {
      return { value: item.id, label: item.name };
    });
  };

  /*
  const menuitems = [
    {
        label: 'Categories',
        items:[
            {
                label: 'Category 1',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    items: [
                      {
                        label: 'Project 1',
                      },
                      {
                          label: 'Project 2',
                      }           ,
                      {
                          label: 'Project 3',
                      },
                      {
                          label: 'Project 4',
                      }
                    ]
                  },
                  {
                    label: 'Open',
                    icon: 'pi pi-fw pi-download',
                    items: [
                      {
                          label: 'Project 1',
                        },
                        {
                            label: 'Project 2',
                        }           ,
                        {
                            label: 'Project 3',
                        },
                        {
                            label: 'Project 4',
                        }
                    ]
                  }
                ]
            },
            {
                label: 'Edit',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    items: [
                      {
                        label: 'Edit Project 1',
                      },
                      {
                          label: 'Edit Project 2',
                      }           ,
                      {
                          label: 'Edit Project 3',
                      },
                      {
                          label: 'Edit Project 4',
                      }
                    ]
                  },
                  {
                    label: 'Open',
                    icon: 'pi pi-fw pi-download',
                    items: [
                      {
                          label: 'Edit Project 1',
                        },
                        {
                            label: 'Edit Project 2',
                        }           ,
                        {
                            label: 'Edit Project 3',
                        },
                        {
                            label: 'Edit Project 4',
                        }
                    ]
                  }
                ]
            },
        ]
    },
    {
        label: 'About Us',
    }  
    
  ];
  */

  return (
    <div
      className={
        "flex relative w-full z-40 rounded-lg md:rounded-2xl h-8 md:h-14 text-lg " +
        className
      }
    >
     





     

      {hasFilter && (
        <div
          className="bg-orange lg:hidden p-1 rounded-l-lg rtl:rounded-l-none rtl:rounded-r-lg flex items-center justify-center"
          onClick={onFilterClick}
        >
          <FilterIcon></FilterIcon>
        </div>
      )}
      {hasShipTo && (
        <Select
          value={restructureSelectItems(countries).find(
            (item) => item.value == watch("country_id")
          )}
          onChange={handleChangeLocation}
          classNames={{
            container: (state) => "min-w-fit lg:block hidden",
            control: (state) =>
              "inline-flex flex-wrap xl:w-[270px]  xl:px-5 px-3 justify-center border-0 rounded-none rounded-l-2xl rtl:rounded-l-none rtl:rounded-r-2xl !outline-none !shadow-none border-r-2 rtl:border-l-2 rtl:border-r-0 border-gray-light h-full items-center bg-white text-gray-dark",
            input: (state) => "text-black",
            menu: (state) =>
              "m-0 mt-[1px] py-4 pr-4 rtl:pr-0 rtl:pl-4 border-0 w-[calc(100%-2px)] rounded-none rounded-b-lg ",
            option: (state) =>
              "text-sm text-black hover:text-white bg-white hover:bg-blue rounded-r-lg rtl:rounded-r-none rtl:rounded-l-lg cursor-pointer",
            menuList: (state) => "pr-2 rtl:pr-0 rtl:pl-2",
          }}
          styles={{
            placeholder: (defaultStyles) => {
              return {
                ...defaultStyles,
                color: "#4A4A4A",
              };
            },
            menuList: (defaultStyles) => {
              return {
                ...defaultStyles,
                "-webkit-mask-image":
                  "linear-gradient(to bottom, black 95%, transparent 100%)",
                "::-webkit-scrollbar": {
                  width: "7px",
                  height: "0px",
                  overflow: "visible",
                },
                "::-webkit-scrollbar-track": {
                  background: "#DEDEDE",
                  borderLeft: "3px solid white",
                  borderRight: "3px solid white",
                },
                "::-webkit-scrollbar-thumb": {
                  background: "#292D32",
                },
                "::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
              };
            },
          }}
          components={{
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <ArrowDownIcon></ArrowDownIcon>
              </components.DropdownIndicator>
            ),
            IndicatorSeparator: () => null,
            Control: ({ children, ...subProps }) => (
              <components.Control {...subProps}>
                {
                  <LocationIcon className="ml-2 rtl:mr-2 rtl:ml-0 [&>path]:stroke-black [&>g]:stroke-black hidden lg:flex"></LocationIcon>
                }{" "}
                {children}
              </components.Control>
            ),
            Input: ({ children, ...subProps }) => (
              <components.Input {...subProps} aria-label="location">
                {children}
              </components.Input>
            ),
          }}
          placeholder={t("msearch_location")}
          options={restructureSelectItems(countries)}
        />
      )}
      {hasCategory && (
        <Select
          value={restructureSelectItems(categories).find(
            (item) => item.value == watch("category_id")
          )}
          onChange={handleChangeCategory}
          classNames={{
            container: (state) => "min-w-fit lg:block hidden",
            control: (state) =>
              `inline-flex flex-wrap xl:w-[270px]  xl:px-5 px-3 justify-center border-0 rounded-none ${
                hasShipTo
                  ? ""
                  : "rounded-l-2xl rtl:rounded-l-none rtl:rounded-r-2xl"
              } !outline-none !shadow-none border-r-2 rtl:border-r-0 rtl:border-l-2 border-gray-light h-full items-center bg-white text-gray-dark`,
            input: (state) => "text-black",
            menu: (state) =>
              "m-0 mt-[1px] py-4 pr-4 rtl:pr-0 rtl:pl-4 border-0 w-[calc(100%-2px)] rounded-none rounded-b-lg ",
            option: (state) =>
              "text-sm text-black hover:text-white bg-white hover:bg-blue rounded-r-lg rtl:rounded-l-lg rtl:rounded-r-none cursor-pointer",
            menuList: (state) => "pr-2 rtl:pl-2 rtl:pr-0",
          }}
          styles={{
            placeholder: (defaultStyles) => {
              return {
                ...defaultStyles,
                color: "#4A4A4A",
              };
            },
            menuList: (defaultStyles) => {
              return {
                ...defaultStyles,
                ...(categories.length > 3 && {
                  "-webkit-mask-image":
                    "linear-gradient(to bottom, black 95%, transparent 100%)",
                  "::-webkit-scrollbar": {
                    width: "7px",
                    height: "0px",
                    overflow: "visible",
                  },
                  "::-webkit-scrollbar-track": {
                    background: "#DEDEDE",
                    borderLeft: "3px solid white",
                    borderRight: "3px solid white",
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: "#292D32",
                  },
                  "::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }),
              };
            },
          }}
          components={{
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <ArrowDownIcon></ArrowDownIcon>
              </components.DropdownIndicator>
            ),
            IndicatorSeparator: () => null,
            Control: ({ children, ...subProps }) => (
              <components.Control {...subProps}>
                {
                  <CategoryIcon className="ml-2 [&>path]:stroke-black [&>g]:stroke-black hidden lg:flex"></CategoryIcon>
                }{" "}
                {children}
              </components.Control>
            ),
            Input: ({ children, ...subProps }) => (
              <components.Input {...subProps} aria-label="category">
                {children}
              </components.Input>
            ),
          }}
          placeholder={t("msearch_category")}
          options={restructureSelectItems(categories)}
        />
      )}
      {hasSortBy && (
        <Select
          value={restructureSelectItems(sortBy).find(
            (item) => item.value == watch("order_by")
          )}
          onChange={handleChangeSort}
          classNames={{
            container: (state) => "min-w-fit lg:block hidden",
            control: (state) =>
              `inline-flex flex-wrap xl:w-[270px]  xl:px-5 px-3 justify-center border-0 rounded-none !outline-none !shadow-none border-r-2 border-gray-light h-full items-center bg-white text-gray-dark`,
            input: (state) => "text-black",
            menu: (state) =>
              "m-0 mt-[1px] py-4 pr-4 border-0 w-[calc(100%-2px)] rounded-none rounded-b-lg ",
            option: (state) =>
              "text-sm text-black hover:text-white bg-white hover:bg-blue rounded-r-lg cursor-pointer",
            menuList: (state) => "pr-2 rtl:pr-0 rtl:pl-2",
          }}
          styles={{
            placeholder: (defaultStyles) => {
              return {
                ...defaultStyles,
                color: "#4A4A4A",
              };
            },
            menuList: (defaultStyles) => {
              return {
                ...defaultStyles,
                ...(sortBy.length > 3 && {
                  "-webkit-mask-image":
                    "linear-gradient(to bottom, black 95%, transparent 100%)",
                  "::-webkit-scrollbar": {
                    width: "7px",
                    height: "0px",
                    overflow: "visible",
                  },
                  "::-webkit-scrollbar-track": {
                    background: "#DEDEDE",
                    borderLeft: "3px solid white",
                    borderRight: "3px solid white",
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: "#292D32",
                  },
                  "::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }),
              };
            },
          }}
          components={{
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <ArrowDownIcon></ArrowDownIcon>
              </components.DropdownIndicator>
            ),
            IndicatorSeparator: () => null,
            Control: ({ children, ...subProps }) => (
              <components.Control {...subProps}>
                {
                  <SortIcon className="ml-2 rtl:mr-2 rtl:ml-0 [&>path]:stroke-black [&>g]:stroke-black hidden lg:flex"></SortIcon>
                }{" "}
                {children}
              </components.Control>
            ),
            Input: ({ children, ...subProps }) => (
              <components.Input {...subProps} aria-label="orderby">
                {children}
              </components.Input>
            ),
          }}
          placeholder={t("msearch_order")}
          options={restructureSelectItems(sortBy)}
        />
      )}
      {hasCategory && (
        <input className="hidden" {...register("category_id")}></input>
      )}
      {hasSortBy && (
        <input className="hidden" {...register("order_by")}></input>
      )}
      {hasShipTo && (
        <input className="hidden" {...register("country_id")}></input>
      )}
      <input
        {...register("search_key")}
        id="search"
        className={`block  p-2.5 pl-6 rtl:pr-6 rtl:pl-2.5 w-full  z-20 ${
          hasShipTo || hasCategory || hasSortBy
            ? "lg:rounded-none rtl:lg:rounded-none rounded-l-lg rtl:rounded-r-lg rtl:rounded-l-none"
            : `lg:rounded-l-2xl rtl:lg:rounded-r-2xl ${
                hasFilter
                  ? "rounded-none lg:rounded-r-none rtl:lg:rounded-l-none"
                  : "rtl:rounded-l-none rounded-l-lg rtl:rounded-r-lg"
              }`
        } outline-none text-gray-900 bg-white`}
        placeholder={t("msearch_search")}
        aria-label="main search"
      />
      <button
        type="submit"
        className="inline-flex transition-all px-1.5 md:px-7 h-full lg:rounded-r-2xl rounded-r-lg rtl:lg:rounded-l-2xl rtl:rounded-l-lg rtl:rounded-r-none  items-center bg-gray-dark hover:bg-gray-dark-2 text-white "
      >
        <SearchIcon className="lg:mr-3 rtl:lg:ml-3 rtl:mr-0"></SearchIcon>{" "}
        <span className="hidden lg:inline-flex">{t("msearch_search_btn")}</span>
        <span className="sr-only">Search</span>
      </button>
    </div>
  );
};

export default MultiSearchInput;
