import React, { useState } from "react";
import styles from "./SiraafMenu.module.css"; // Import the CSS module

const SiraafMenu = ({ items }) => {

    const [level1ActiveIndex, setLevel1ActiveIndex] = useState(null);
    const [level2ActiveIndex, setLevel2ActiveIndex] = useState(null);

    const level1Click = (index) => {
        setLevel1ActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const level2Click = (index) => {
        setLevel2ActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className={styles.siraafmenu}>
            <div className={styles.level1Box}>
                {items && items.map((level1, index) => (
                    <div key={index} className={`p-2 ${styles.level1BoxItem} ${level1ActiveIndex == index ? styles.level1BoxItemActive : styles.level1BoxItemInactive}`} onClick={() => level1Click(index)}>
                        <span className={styles.menuLabel}>{level1.label}</span>
                    </div>
                ))}
            </div>
            <div className={`${styles.dropdown} rounded-xl`}>
                <div className={`p-2 ${styles.level2Box}`}>
                    {items && items.map((level1, index) => (
                        level1ActiveIndex === index ? (
                            <ul className={styles.menuList}>
                                {level1.items && level1.items.map((level2, index) => (
                                    <li key={index} className={`${styles.level2} ${level2ActiveIndex == index ? styles.menuContainerActive : styles.menuContainerInactive}`} onClick={() => level2Click(index)}>
                                        <span className={styles.menuLabel}>{level2.label}</span>
                                    </li>
                                ))}
                            </ul>
                        ) : null
                    ))}
                </div>
                <div className={`p-2 ${styles.level3Box}`}>
                    {items && items.map((level1, index) => (
                        level1ActiveIndex === index ? (
                            level1.items && level1.items.map((level2, index) => (
                                level2ActiveIndex === index ? (
                                    <div key={index} className={`${styles.menuContainer} ${level2ActiveIndex == index ? styles.menuContainerActive : styles.menuContainerInactive}`} onClick={() => handleClick(index)}>
                                        {level2.items && (
                                            <div className={styles.menuList}>
                                                {level2.items.map((level3, subIndex) => (
                                                    <div key={subIndex} className={styles.level2}>
                                                        <span className={styles.menuLabel}>{level3.label}</span>
                                                        {level3.items && (
                                                            <ul className={styles.menuList}>
                                                                {level3.items.map((level3, subIndex) => (
                                                                    <li key={subIndex} className={styles.level2}>
                                                                        <span className={styles.menuLabel}>{level3.label}</span>

                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ) : null
                            ))
                        ): null
                    ))}
                </div>
            </div>                    
        </div>
    );
};

export default SiraafMenu;
